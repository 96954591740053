.inclusive-forgotPassword-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edf0f7;
}

.forgotPassword-big-wrapper {
  max-width: 520px;
  height: 70%;
  border-radius: 9px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.forgotPassword-big-wrapper form {
  position: relative;
  margin: 0 auto;
  width: 73%;
}

.top-forgotpassword-explain {
  margin-top: 10vh;
}

.top-forgotpassword-explain h3 {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  margin-bottom: 1.4rem;
  font-size: 2.4rem;
  font-family: "Signika Negative", sans-serif;
  text-align: center;
}

.top-forgotpassword-explain p {
  color: #979797;
  font-family: "Inter", sans-serif;
  font-size: 0.8rem;
  line-height: 22px;
  text-align: center;
}

.forgotPassword-big-wrapper form label {
  position: absolute;
  top: -10px;
  left: 14px;
  font-size: 0.8rem;
  font-family: "Inter", sans-serif;
  background-color: white;
  color: #646464;
  padding: 0 6px;
}

.forgotPassword-big-wrapper form .input-wrapper {
  position: relative;
  margin-top: 2.3rem;
}

.forgotPassword-big-wrapper form input {
  outline: 0;
  width: 100%;
  margin-bottom: 1.9rem;
  padding: 18px;
  padding-top: 22px;
  border: #dadcdd solid 1px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #646464;
}

.forgotPassword-big-wrapper form input:focus-within {
  border: #0275d8 solid 1px;
}

.forgotPassword-big-wrapper form input:focus + label {
  color: #0275d8 !important;
}

.forgotPassword-big-wrapper form button {
  cursor: pointer;
  border: none;
  outline: 0;
  background-color: #0275d8;
  width: 100%;
  text-align: center;
  color: white;
  padding: 15px;
  border-radius: 6px;
  margin-top: 14px;
  letter-spacing: 0.4px;
}

.forgotPassword-big-wrapper form .error_message {
  width: 100%;
  border-radius: 8px;
  margin-top: 0.1rem;
  margin-bottom: 1.5rem;
  background-color: rgba(245, 72, 72, 0.9);
  color: white;
  font-size: 0.9rem;
  text-align: center;
}

.success_message {
  background-color: rgb(67, 247, 127);
  padding: 8px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 10px;
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  color: #3f3f3f;
}

.success_message a {
  text-decoration: none;
  color: #0275d8;
  margin-left: 10px;
}

.back_home {
  color: black;
  font-size: 2.1rem;
  position: absolute;
  top: 10px;
  left: 18px;
}
