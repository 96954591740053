.scroll-to-bottom {
  color: white;
  display: flex;
  position: fixed;
  right: 2rem;
  top: 6rem;
  transition-property: all;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: #0063a5;
  cursor: pointer;
  border: none;
}

.scroll-to-bottom-icon {
  width: 2rem;
  height: 2rem;
}

.scroll-to-bottom:hover {
  background: #024d80;
}
