.inclusive-login-page {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edf0f7;
}

.login-big-wrapper {
  width: 520px;
  height: 70%;
  border-radius: 9px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  position: relative;
}

/* .section-wrapper {
  width: 50%;
  padding: 2.6rem 4rem;
} */

/* .login-banner-section {
  background-color: #0507d4;
  border-radius: 0 9px 9px 0;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
} */

@media only screen and (max-width: 860px) {
  /* .login-banner-section {
    display: none;
  } */
  /* .section-wrapper {
    max-width: 580px;
    width: 100%;
    margin: 0 auto;
    padding: 1.7rem 10vw;
  } */
  .login-big-wrapper {
    width: 80%;
    padding: 2.2rem 0;
  }
}

.top-suggest_register {
  font-size: 0.9rem;
  margin-top: 2rem;
  text-align: center;
}

.top-suggest_register span {
  margin-right: 4px;
  font-weight: 600;
}

.top-suggest_register a {
  font-size: 0.8rem;
  color: #0275d8;
  text-decoration: none;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

.top-login-explain {
  margin-top: 6vw;
}

.top-login-explain h2 {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  margin-bottom: 2.2rem;
  font-size: 2.4rem;
  font-family: "Signika Negative", sans-serif;
  text-align: center;
}

.login-big-wrapper form {
  position: relative;
  margin: 0 auto;
  width: 73%;
}

.login-big-wrapper form label {
  position: absolute;
  top: -10px;
  left: 14px;
  font-size: 0.8rem;
  font-family: "Inter", sans-serif;
  background-color: white;
  color: #646464;
  padding: 0 6px;
}

.login-big-wrapper form .input-wrapper {
  position: relative;
}

.login-big-wrapper form input {
  outline: 0;
  width: 100%;
  margin-bottom: 1.9rem;
  padding: 18px;
  padding-top: 22px;
  border: #dadcdd solid 1px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #646464;
}

.login-big-wrapper form input:focus-within {
  border: #0275d8 solid 1px;
}

.login-big-wrapper form input:focus + label {
  color: #0275d8 !important;
}

.login-screen__forgotpassword {
  float: right;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-size: 0.7rem;
  margin-top: -13px;
  color: #0275d8;
  font-weight: 600;
  cursor: pointer;
  padding: 3px 6px;
}

.login-big-wrapper form button {
  cursor: pointer;
  border: none;
  outline: 0;
  background-color: #0275d8;
  width: 100%;
  text-align: center;
  color: white;
  padding: 15px;
  border-radius: 6px;
  margin-top: 14px;
  letter-spacing: 0.4px;
}

.error_message {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  margin-top: -1.2rem;
  background-color: rgba(245, 72, 72, 0.9);
  color: white;
  padding: 8px;
  font-size: 0.9rem;
  text-align: center;
}

.back_home {
  color: black;
  font-size: 2.1rem;
  position: absolute;
  top: 10px;
  left: 18px;
}
