@import url("https://fonts.googleapis.com/css2?family=Balsamiq+Sans:wght@700&display=swap");
.Inclusive-editStory-page {
  width: 90%;
  margin: 1.2rem auto;
}

.Inclusive-editStory-page .editStory-form {
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.editStory-form input#title {
  width: 100%;
  height: 55px;
  outline: 0;
  border: none;
  border: rgb(235, 235, 235) solid 1px;
  font-size: 1.4rem;
  padding-left: 1rem;
  font-weight: 450;
  font-family: "Balsamiq Sans", cursive;
  border-bottom: none;
}

.StoryImageField {
  border: rgb(235, 235, 235) solid 1px;
  width: 100%;
  height: 240px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin-top: 1rem;
  position: relative;
}

.StoryImageField svg {
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.7);
}

.StoryImageField .txt {
  width: 350px;
  height: auto;
  margin-top: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
}

.StoryImageField [type="file"] {
  width: 100%;
  height: 200px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.editStory-btn {
  width: 100%;
  background-color: #0063a5;
  padding: 0.7rem;
  color: white;
  margin-top: 1.9rem;
  border: none;
  border-radius: 6px;
  font-size: 1.1rem;
}

.dis-btn {
  width: 100%;
  background-color: #0063a5;
  padding: 0.7rem;
  color: white;
  margin-top: 1.9rem;
  border: none;
  border-radius: 6px;
  font-size: 1.1rem;
  opacity: 0.4;
}

.editStory-form .error_msg {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  background-color: rgba(245, 72, 72, 0.9);
  color: white;
  padding: 8px;
  font-size: 0.9rem;
  text-align: center;
}

.editStory-form .success_msg {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  background-color: rgba(14, 218, 75, 0.9);
  color: white;
  padding: 8px;
  font-size: 0.9rem;
  text-align: center;
}

.editStory-form .success_msg a {
  color: black;
  font-weight: bold;
  padding-left: 1rem;
}

.currentImage {
  width: 100%;
  height: 280px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin-top: 2rem;
  position: relative;
}

.currentImage .absolute {
  color: white;
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 0.9rem;
  font-weight: bold;
  opacity: 0.6;
  font-family: "Mukta ", sans-serif;
  z-index: 52;
}

.currentImage img {
  width: 100%;
  height: 100%;
}

.currentImage span {
  position: absolute;
  top: 0px;
  left: 0px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 6px 10px;
  cursor: pointer;
  font-weight: 900;
  color: crimson;
}

.language-markup {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 6px;
}

.language-markup code {
  padding: 0;
  background-color: transparent;
}

code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(175, 184, 193, 0.2);
  border-radius: 6px;
  color: black;
}

@media only screen and (max-width: 1001px) {
  .Inclusive-editStory-page {
    width: 100%;
  }
}

@media only screen and (max-width: 651px) {
  .Inclusive-editStory-page .editStory-form {
    width: 80vw;
  }
  .ck.ck-reset.ck-editor.ck-rounded-corners {
    width: 100% !important;
  }
}
