@import url("https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap&family=Hammersmith+One&family=Londrina+Solid:wght@900&display=swap");
.story-card-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  justify-items: center;
  margin: 20px 0;
}

.story-card {
  max-width: 300px;
  overflow: hidden;
  height: 500px;
  padding: 15px;
  margin: 10px 0;
  position: relative;
  border-radius: 6px;
  border: 1px solid rgba(121, 118, 118, 0.3);
  box-shadow: 0 0 10px 5px rgba(88, 88, 88, 0.1);
  transition: 350ms ease all;
}

.story-card:hover {
  transform: scale(1.01);
}

.story-card a.story-link {
  text-decoration: none;
}

.story-card img.story-image {
  width: 296px;
  height: 225px;
  margin-top: -14px;
  margin-left: -9px;
  border-radius: 6px 6px 0 0;
}

.story-card .story-content-wrapper {
  width: 288px;
  margin-left: -5px;
}

.story-card h5.story-title {
  font-size: 1.3rem;
  line-height: 30px;
  height: 40px;
  letter-spacing: 0.6px;
  color: black;
  padding: 10px 3px;
  font-weight: bold;
  word-wrap: break-word;
  font-family: sans-serif;
}

.story-card h5.story-title:hover {
  text-decoration: underline;
}

.story-card .story-text {
  height: 105px;
  overflow: hidden;
  word-wrap: break-word;
  margin-bottom: 50px;
}

.story-card .story-text,
.story-card .story-text * {
  color: rgb(92, 92, 92);
  font-size: 15px;
  width: 270px;
  font-weight: normal;
}

.story-author-container {
  width: 288px;
  display: flex;
}

.story-author {
  display: flex;
}

.story-author img {
  margin-top: -24px;
  margin-right: 6px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff, 0 3px 7px rgba(90, 97, 105, 0.5);
}

.story-author h6 {
  margin-top: -14px;
}

.story-date {
  font-family: sans-serif;
  color: #5a6169;
  margin-left: 78px;
  margin-top: -14px;
  font-size: 14px;
}

/* Extra large devices (large laptops and desktops, 1280px and up)
@media only screen and (min-width: 1280px) {
  .story-card-wrapper {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    column-gap: 1rem;
    row-gap: 1rem;
  }
}

/* Extra large devices (large laptops and desktops, 1024px and down) */
/* @media only screen and (max-width: 1024px) {
  .story-card {
    height: 520px;
  }

  .story-card-wrapper {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .story-card img.story-image {
    width: 296px;
    height: 250px;
  }
} */

/* Extra large devices (large laptops and desktops, 1000px and down) */
/* @media only screen and (max-width: 1000px) {
  .story-card {
    height: 520px;
  }

  .story-card-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .story-card img.story-image {
    width: 296px;
    height: 250px;
  }
} */

/* Medium devices (landscape tablets, 768px and down) */
/* @media only screen and (max-width: 768px) {
  .story-card-wrapper {
    grid-template-columns: 348px 348px;
    column-gap: 1rem;
    margin-left: 2rem;
  }

  .story-card img.story-image {
    width: 296px;
    height: 250px;
  }
} */

/* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {
  .story-card {
    height: 520px;
  }

  .story-card-wrapper {
    grid-template-columns: 348px;
    margin-left: 30px;
  }

  .story-card img.story-image {
    width: 296px;
    height: 250px;
  }
} */

/* Extra small devices (phones, 400px and down) */
/* @media only screen and (max-width: 400px) {
  .story-card {
    height: 520px;
  }

  .story-card-wrapper {
    grid-template-columns: 348px;
    margin-left: 10px;
  }

  .story-card img.story-image {
    width: 296px;
    height: 250px;
  }
}  */
